import "./index.css";

export function Logo() {
  return (
    <svg
      width="101"
      height="33"
      viewBox="0 0 101 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1_1025)">
        <path
          d="M35.5898 9.61465L40.0776 20.4271C40.1794 20.7077 40.409 20.8355 40.7405 20.8355C41.0969 20.8355 41.3265 20.7077 41.4543 20.4271L46.1207 9.61465H49.028L43.8255 21.6262C43.2644 22.9011 42.27 23.3853 40.7394 23.3853C39.2089 23.3853 38.1896 22.8752 37.6794 21.6262L32.7324 9.61465H35.5888H35.5898Z"
          fill="#272732"
        />
        <path
          d="M54.1797 4.53989V7.67682H51.4512V4.53989H54.1797ZM54.1538 9.61467V23.0799H51.4512V9.61467H54.1538Z"
          fill="#272732"
        />
        <path
          d="M66.6506 9.61466C70.2209 9.61466 72.4652 11.8081 72.4652 16.3467C72.4652 20.8854 70.2209 23.0788 66.6506 23.0788H60.6833V28.4851H57.9807V15.3773C57.9807 11.5006 59.6889 9.61362 62.9277 9.61362H66.6506V9.61466ZM60.6833 20.4531H66.3701C68.7163 20.4531 69.7367 19.254 69.7367 16.3478C69.7367 13.4415 68.7163 12.2165 66.3701 12.2165H63.7184C61.525 12.2165 60.6833 13.1859 60.6833 15.5061V20.4531Z"
          fill="#272732"
        />
        <path
          d="M77.7945 19.6115V23.0799H74.8872V19.6115H77.7945Z"
          fill="#272732"
        />
        <path
          d="M89.6781 9.61465C93.0447 9.61465 94.3705 11.5016 94.3705 14.0514V20.3762C94.3705 22.3141 93.8604 23.0799 91.591 23.0799H85.2923C82.1044 23.0799 80.7786 21.5244 80.7786 18.9745C80.7786 16.4247 82.0026 15.0988 85.3182 15.0988H91.7189V14.0785C91.7189 13.0332 91.1318 12.2165 89.6532 12.2165H81.5942V9.61569H89.6781V9.61465ZM91.7189 19.7642V17.2393H85.3691C83.9415 17.2393 83.4313 17.8264 83.4313 18.8207C83.4313 19.8151 83.9664 20.4531 85.3182 20.4531H90.9801C91.5162 20.4531 91.7199 20.2495 91.7199 19.7642H91.7189Z"
          fill="#272732"
        />
        <path
          d="M100.925 4.53989V7.67682H98.1965V4.53989H100.925ZM100.899 9.61467V23.0799H98.1965V9.61467H100.899Z"
          fill="#272732"
        />
        <path
          d="M13.855 0.5L0 8.49246V24.4879L13.4965 32.28L13.855 32.4804L14.2135 32.28L27.71 24.4879V8.49246L13.855 0.5ZM26.0124 9.16728L22.7859 14.7557L16.7335 9.7683L21.025 6.28873L26.0124 9.16728ZM13.855 2.14489L19.6965 5.51901L15.4788 8.94586V8.82987C15.4788 8.42919 15.1519 8.09178 14.7407 8.09178H13.855H12.9587C12.5581 8.09178 12.2207 8.41865 12.2207 8.82987V8.94586L8.003 5.51901L13.855 2.14489ZM6.68498 6.28873L10.9659 9.7683L4.91357 14.7557L1.68706 9.16728L6.68498 6.28873ZM1.434 23.6655V11.5713L11.9043 29.7073L1.434 23.6655ZM13.855 30.2345L5.64111 16.0104L12.2207 10.5907V10.6329C12.2207 11.0336 12.5475 11.371 12.9587 11.371H13.855H14.7513C15.1519 11.371 15.4893 11.0441 15.4893 10.6329V10.5907L22.0689 16.0104L13.855 30.2345ZM26.276 23.6655L15.8057 29.7073L26.276 11.5713V23.6655Z"
          fill="url(#paint0_linear_1_1025)"
        />
        <path
          d="M13.855 18.0665C14.5072 18.0665 15.036 17.5378 15.036 16.8856C15.036 16.2334 14.5072 15.7047 13.855 15.7047C13.2028 15.7047 12.6741 16.2334 12.6741 16.8856C12.6741 17.5378 13.2028 18.0665 13.855 18.0665Z"
          fill="url(#paint1_linear_1_1025)"
        />
        <path
          d="M13.855 23.0434C14.5072 23.0434 15.036 22.5146 15.036 21.8624C15.036 21.2102 14.5072 20.6815 13.855 20.6815C13.2028 20.6815 12.6741 21.2102 12.6741 21.8624C12.6741 22.5146 13.2028 23.0434 13.855 23.0434Z"
          fill="url(#paint2_linear_1_1025)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1_1025"
          x1="3.64236"
          y1="6.2806"
          x2="24.919"
          y2="27.5572"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A06C2C" />
          <stop offset="0.1373" stopColor="#AA762A" />
          <stop offset="0.2869" stopColor="#D49C2F" />
          <stop offset="0.4267" stopColor="#FAEFA0" />
          <stop offset="0.6255" stopColor="#C38C2C" />
          <stop offset="0.7412" stopColor="#A56127" />
          <stop offset="0.8943" stopColor="#DFAA2B" />
          <stop offset="1" stopColor="#A46227" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_1025"
          x1="3.44609"
          y1="6.4769"
          x2="24.7227"
          y2="27.7535"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A06C2C" />
          <stop offset="0.1373" stopColor="#AA762A" />
          <stop offset="0.2869" stopColor="#D49C2F" />
          <stop offset="0.4267" stopColor="#FAEFA0" />
          <stop offset="0.6255" stopColor="#C38C2C" />
          <stop offset="0.7412" stopColor="#A56127" />
          <stop offset="0.8943" stopColor="#DFAA2B" />
          <stop offset="1" stopColor="#A46227" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1_1025"
          x1="0.95632"
          y1="8.96667"
          x2="22.233"
          y2="30.2433"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A06C2C" />
          <stop offset="0.1373" stopColor="#AA762A" />
          <stop offset="0.2869" stopColor="#D49C2F" />
          <stop offset="0.4267" stopColor="#FAEFA0" />
          <stop offset="0.6255" stopColor="#C38C2C" />
          <stop offset="0.7412" stopColor="#A56127" />
          <stop offset="0.8943" stopColor="#DFAA2B" />
          <stop offset="1" stopColor="#A46227" />
        </linearGradient>
        <clipPath id="clip0_1_1025">
          <rect
            width="100.925"
            height="32"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

function SearchSubmitIcon() {
  return (
    <button className="search-submit">
      <svg
        width="15"
        height="12"
        viewBox="0 0 15 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.0271 6.73333L1.56042 11.8208C1.30069 11.9278 1.05625 11.9049 0.827084 11.7521C0.597917 11.5993 0.483334 11.3854 0.483334 11.1104V0.889583C0.483334 0.614582 0.597917 0.400693 0.827084 0.247915C1.05625 0.0951381 1.30069 0.0722218 1.56042 0.179166L14.0271 5.26667C14.3479 5.40417 14.5083 5.64861 14.5083 6C14.5083 6.35139 14.3479 6.59583 14.0271 6.73333ZM1.76667 10.3542L12.4 6L1.76667 1.62292V4.67083L6.46458 6L1.76667 7.32917V10.3542ZM1.76667 10.3542V6V1.62292V4.67083V7.32917V10.3542Z"
          fill="white"
        />
      </svg>
    </button>
  );
}

function Search() {
  return (
    <div className="search">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="60"
        height="69"
        viewBox="0 0 60 69"
        fill="none"
      >
        <path
          d="M30.0001 0L0.410156 17.0694V51.2306L29.2344 67.8721L30.0001 68.3L30.7657 67.8721L59.59 51.2306V17.0694L30.0001 0ZM55.9644 18.5106L49.0736 30.4456L36.1477 19.7942L45.313 12.3629L55.9644 18.5106ZM30.0001 3.51296L42.4756 10.719L33.468 18.0377V17.79C33.468 16.9343 32.7699 16.2136 31.8917 16.2136H30.0001H28.086C27.2302 16.2136 26.5096 16.9117 26.5096 17.79V18.0377L17.502 10.719L30.0001 3.51296ZM14.6872 12.3629L23.8299 19.7942L10.904 30.4456L4.01319 18.5106L14.6872 12.3629ZM3.47273 49.4741V23.6449L25.8341 62.3775L3.47273 49.4741ZM30.0001 63.5035L12.4578 33.1254L26.5096 21.5506V21.6407C26.5096 22.4964 27.2077 23.217 28.086 23.217H30.0001H31.9142C32.7699 23.217 33.4905 22.519 33.4905 21.6407V21.5506L47.5423 33.1254L30.0001 63.5035ZM56.5274 49.4741L34.1661 62.3775L56.5274 23.6449V49.4741Z"
          fill="url(#paint0_linear_1_828)"
        />
        <path
          d="M30 37.5166C31.393 37.5166 32.5222 36.3874 32.5222 34.9945C32.5222 33.6015 31.393 32.4723 30 32.4723C28.6071 32.4723 27.4779 33.6015 27.4779 34.9945C27.4779 36.3874 28.6071 37.5166 30 37.5166Z"
          fill="url(#paint1_linear_1_828)"
        />
        <path
          d="M30 48.1455C31.393 48.1455 32.5222 47.0163 32.5222 45.6234C32.5222 44.2305 31.393 43.1013 30 43.1013C28.6071 43.1013 27.4779 44.2305 27.4779 45.6234C27.4779 47.0163 28.6071 48.1455 30 48.1455Z"
          fill="url(#paint2_linear_1_828)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1_828"
            x1="8.18908"
            y1="12.3455"
            x2="53.6293"
            y2="57.7857"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A06C2C" />
            <stop offset="0.1373" stopColor="#AA762A" />
            <stop offset="0.2869" stopColor="#D49C2F" />
            <stop offset="0.4267" stopColor="#FAEFA0" />
            <stop offset="0.6255" stopColor="#C38C2C" />
            <stop offset="0.7412" stopColor="#A56127" />
            <stop offset="0.8943" stopColor="#DFAA2B" />
            <stop offset="1" stopColor="#A46227" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1_828"
            x1="7.76984"
            y1="12.7648"
            x2="53.21"
            y2="58.2049"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A06C2C" />
            <stop offset="0.1373" stopColor="#AA762A" />
            <stop offset="0.2869" stopColor="#D49C2F" />
            <stop offset="0.4267" stopColor="#FAEFA0" />
            <stop offset="0.6255" stopColor="#C38C2C" />
            <stop offset="0.7412" stopColor="#A56127" />
            <stop offset="0.8943" stopColor="#DFAA2B" />
            <stop offset="1" stopColor="#A46227" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1_828"
            x1="2.45248"
            y1="18.0821"
            x2="47.8927"
            y2="63.5223"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A06C2C" />
            <stop offset="0.1373" stopColor="#AA762A" />
            <stop offset="0.2869" stopColor="#D49C2F" />
            <stop offset="0.4267" stopColor="#FAEFA0" />
            <stop offset="0.6255" stopColor="#C38C2C" />
            <stop offset="0.7412" stopColor="#A56127" />
            <stop offset="0.8943" stopColor="#DFAA2B" />
            <stop offset="1" stopColor="#A46227" />
          </linearGradient>
        </defs>
      </svg>
      <div className="input-wrapper">
        <input placeholder="Type what’s on your mind..." />
        <SearchSubmitIcon />
      </div>
    </div>
  );
}

function Header() {
  return (
    <header className="header">
      <div className="container">
        <a href="/" className="link">
          <Logo />
        </a>
        <button>Contact Us</button>
      </div>
    </header>
  );
}

export default function App() {
  return (
    <div className="app">
      <Header />
      <div className="page-content container">
        <Search />
      </div>
    </div>
  );
}
